<script lang="ts" setup>
import type { EditorialTextModule } from '@integration-layer/ampliance/schemas/editorial-text-module-schema.localized'
import type { EditorialTextModuleProps } from '@design-system/components/Editorial/EditorialTextModule.props'

const props = defineProps<{
  deliveryId: string
  locale: string
  vse?: string
  content?: EditorialTextModule
}>()

const data = await useComponentResponse<EditorialTextModule>(props)
</script>

<template>
  <EditorialTextModule
    v-if="data"
    v-bind="data as unknown as EditorialTextModuleProps"
  />
</template>
